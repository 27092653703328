<template>
    <div>
        <div>
            <b-tabs pills v-model="tab" class="border-2">
                <b-tab title="مرحلة التعليم الجامعي" active> </b-tab>
                <b-tab title="مرحلة التعليم المدرسي"> </b-tab>
            </b-tabs>
        </div>
        <ek-table
            :columns="columns"
            :items="branches"
            @delete-selected="deleteUsers"
            @details="detailsAppPanel"
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                <span> {{ new Date(value).toLocaleDateString() }}</span>
            </template>
            <template slot="items.facultyId" slot-scope="{ props }">
                <span
                    v-if="facultyName.find((u) => u.id == props.row.facultyId)"
                >
                    {{
                        facultyName.find((u) => u.id == props.row.facultyId)
                            .name
                    }}
                </span>
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
export default {
    data() {
        return {
            tab: 0,
        };
    },
    components: {},
    computed: {
        ...mapState({
            columns: (state) => state.branches.columns,
            facultiesList: (state) => state.faculty.facultyName,
            facultyName: (state) => state.faculty.facultyName,
        }),
        ...mapGetters(["branches"]),
    },
    methods: {
        ...mapActions([
            "getBranchList",
            "getFacultyName",
            "getGetUnRead",
            "getNamesUniversity",
        ]),
        detailsAppPanel({ row }) {
            this.$store.commit("IS_DIALOG_OPEN", true);
            this.$store.commit("SET_BRANCH_DTO", row);
        },
        deleteUsers(e) {
            this.$store.dispatch("deleteDepartments", e);
        },
    },
    created() {
        this.getBranchList(0);
        this.getFacultyName(0);
        this.getNamesUniversity(0);
        this.getGetUnRead();
    },
    watch: {
        tab: function (newTab) {
            if (newTab === 0) {
                this.getBranchList(0);
                this.getFacultyName(0);
                this.getNamesUniversity(0);
            } else {
                this.getBranchList(1);
                this.getFacultyName(1);
                this.getNamesUniversity(1);
            }
        },
    },
};
</script>
